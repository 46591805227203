var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SelectionModel } from "@angular/cdk/collections";
import { registerLocaleData } from "@angular/common";
import localePl from "@angular/common/locales/pl";
import { OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatSnackBar } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { FuseTranslationLoaderService } from "../../../../../@fuse/services/translation-loader.service";
import { AuthService } from "../../../../services/auth.service";
import { OrdersService } from "../../../../services/orders.service";
import { UsersService } from "../../../../services/users.service";
import { OrderTypes } from "../../../../shared/consts/orders";
import { List } from "../../../../shared/models/list.class";
import { OrderSatuses, } from "../../../../shared/models/orders.model";
import { RoleTypes, UserTypes, } from "../../../../shared/models/users.model";
import { locale as english } from "../../../../translations/en";
import { locale as polish } from "../../../../translations/pl";
import { TaskExportFormComponent } from "../../../tasks/components/task-forms/task-export-form/task-export-form.component";
import { TaskImportFormComponent } from "../../../tasks/components/task-forms/task-import-form/task-import-form.component";
import { TaskReceivingFormComponent } from "../../../tasks/components/task-forms/task-receiving-form/task-receiving-form.component";
import { OrderExportFormComponent } from "../order-forms/order-export-form/order-export-form.component";
import { OrderImportFormComponent } from "../order-forms/order-import-form/order-import-form.component";
import { OrderReceivingFormComponent } from "../order-forms/order-receiving-form/order-receiving-form.component";
import { OrderTypePickerComponent } from "../order-type-picker/order-type-picker.component";
var OrdersListComponent = /** @class */ (function (_super) {
    __extends(OrdersListComponent, _super);
    function OrdersListComponent(ordersService, matDialog, authService, translateService, usersService, snackBar, _fuseTranslationLoaderService) {
        var _this = _super.call(this) || this;
        _this.ordersService = ordersService;
        _this.matDialog = matDialog;
        _this.authService = authService;
        _this.translateService = translateService;
        _this.usersService = usersService;
        _this.snackBar = snackBar;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.displayedColumns = [
            "select",
            "tags",
            "salesAcceptedDate",
            "customerCompanyName",
            "city",
            "orderType",
            "totalWeight",
            "comment",
            "suggestedDate",
            "status",
            "createdBySurname",
            "createdDate",
        ];
        _this.userTypes = UserTypes;
        _this.roleTypes = RoleTypes;
        _this.companyBranches = [];
        _this.orderStatuses = OrderSatuses;
        _this.tableStatus = ["status"];
        _this.tableFilters = ["orderType", "salesAccepted", "reinvoice"];
        _this.textFilters = ["customerCompanyName", "address", "comment"];
        _this.weightFilters = ["totalWeight"];
        _this.tagsFilters = ["tags"];
        _this.initialUserFilters = {
            orderType: null,
            salesAccepted: "1",
            companyBranchUuid: [],
            status: ["new", "read"],
        };
        _this.orderTypes = OrderTypes;
        _this.canSendOrderLink = false;
        _this.selection = new SelectionModel(true, []);
        _this.listFilters = {};
        _this.filterByTaskDate = false;
        _this.taskForms = {
            receiving: TaskReceivingFormComponent,
            import: TaskImportFormComponent,
            export: TaskExportFormComponent,
        };
        registerLocaleData(localePl);
        _this.user = _this.authService.user.user;
        if (_this.user.userType === _this.userTypes.Manager) {
            _this.tableFilters = [];
            _this.displayedColumns = [
                "tags",
                "salesAcceptedDate",
                "createdDate",
                "customerCompanyName",
                "city",
                "orderType",
                "totalWeight",
                "comment",
                "suggestedDate",
                "status",
                "createdBySurname",
            ];
        }
        if (_this.user.userType !== _this.userTypes.Logistician) {
            _this.tagsFilters = [];
            _this.displayedColumns = [
                "createdDate",
                "salesAcceptedDate",
                "customerCompanyName",
                "city",
                "orderType",
                "totalWeight",
                "comment",
                "suggestedDate",
                "status",
                "createdBySurname",
            ];
        }
        _this.requestParams.sort = "salesAcceptedDate";
        _this.requestParams.order = "desc";
        _this._fuseTranslationLoaderService.loadTranslations(polish, english);
        _this.reloadList$ = ordersService.onEditEvent.subscribe(function () {
            _this.getElementsList();
        });
        _this.getElementsList = function () {
            var filters = _this.getQueryParams();
            if (_this.user.userType === _this.userTypes.Manager &&
                _this.user.roleType !== _this.roleTypes.Admin) {
                filters = __assign({}, _this.getQueryParams(), { companyBranchUuid: _this.user.companyBranchId });
            }
            _this.ordersService[_this.filterByTaskDate
                ? "getOrdersListFilteredByTaskDate"
                : "getOrdersList"](filters).subscribe(function (res) {
                _this.totalCount = res.totalCount;
                _this.dataSource = res.orders;
            });
        };
        return _this;
    }
    OrdersListComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.createSearchFormControl();
        if (this.user.userType !== this.userTypes.Manager ||
            (this.user.userType === this.userTypes.Manager &&
                this.user.roleType === this.roleTypes.Admin)) {
            this.canSendOrderLink = true;
            this.usersService.getCompanyBranchList().subscribe(function (res) {
                res.branches.forEach(function (branch) {
                    _this.companyBranches.push({ name: branch.name, value: branch.uuid });
                });
                _this.companyBranches.push({
                    name: _this.translateService.instant("ORDERS.UNALLOCATED"),
                    value: "unallocated",
                });
                if (_this.user.userType === _this.userTypes.Sales) {
                    _this.companyBranches.forEach(function (branch) {
                        _this.initialUserFilters.companyBranchUuid.push(branch.value);
                    });
                }
            });
        }
        switch (this.user.userType) {
            case this.userTypes.Logistician:
                this.initialUserFilters.companyBranchUuid = [
                    this.user.companyBranchId,
                    "unallocated",
                ];
                this.initialUserFilters.orderType = "all_options";
                break;
            case this.userTypes.Sales:
                var filterParams = {
                    page: 1,
                    limit: 1000,
                    userType: UserTypes.Sales,
                };
                this.usersService
                    .getUsersList(filterParams)
                    .subscribe(function (response) {
                    _this.salesList = response.users.map(function (user) { return ({
                        name: user.name + " " + user.surname,
                        value: user.uuid,
                    }); });
                });
                this.initialUserFilters.orderType = "all_options";
                break;
            case this.userTypes.Manager:
                this.initialUserFilters.companyBranchUuid = [this.user.companyBranchId];
                break;
        }
    };
    OrdersListComponent.prototype.ngOnDestroy = function () {
        this.reloadList$.unsubscribe();
    };
    OrdersListComponent.prototype.addOrder = function () {
        this.disableAnimations = true;
        this.matDialog.open(OrderTypePickerComponent, {
            panelClass: "order-type-picker",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "add",
            },
        });
    };
    OrdersListComponent.prototype.viewOrder = function (orderUuid, orderType) {
        var _this = this;
        var editorDialogRef;
        this.disableAnimations = true;
        this.dataSource.map(function (order) {
            if (order.uuid === orderUuid &&
                order.status === _this.orderStatuses.New &&
                _this.user.userType === _this.userTypes.Logistician) {
                order.status = _this.orderStatuses.Read;
            }
        });
        if (orderType === OrderTypes.Export) {
            editorDialogRef = OrderExportFormComponent;
        }
        else if (orderType === OrderTypes.Import) {
            editorDialogRef = OrderImportFormComponent;
        }
        else {
            editorDialogRef = OrderReceivingFormComponent;
        }
        this.matDialog.open(editorDialogRef, {
            panelClass: "order-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "view",
                orderUuid: orderUuid,
            },
        });
    };
    OrdersListComponent.prototype.masterToggle = function () {
        var _this = this;
        this.isAllSelected()
            ? this.selection.clear()
            : !this.filterByTaskDate
                ? this.dataSource.forEach(function (row) { return _this.selection.select(row); })
                : this.dataSource.forEach(function (row) {
                    return row.status === _this.orderStatuses.Planned &&
                        _this.selection.select(row);
                });
    };
    OrdersListComponent.prototype.isAllSelected = function () {
        var numSelected = this.selection.selected.length;
        var numRows = this.dataSource.length;
        return numSelected === numRows;
    };
    OrdersListComponent.prototype.closeOrders = function (event) {
        var _this = this;
        event.stopPropagation();
        this.ordersService
            .closeOrders(this.selection.selected.map(function (item) { return item.uuid; }))
            .subscribe(function () {
            _this.getElementsList();
            _this.clearSelect();
        });
    };
    OrdersListComponent.prototype.generateTask = function (event) {
        event.stopPropagation();
        var selected = _.uniqBy(this.selection.selected, "uuid");
        if (selected.some(function (item) { return item.companyBranch !== null; })) {
            if (selected.some(function (item) { return item.orderType !== selected[0].orderType; })) {
                this.snackBar.open(this.translateService.instant("ORDERS.MESSAGES.GENERATE_ERROR_WRONG_ORDER_TYPE"), "", { duration: 5000 });
            }
            else if (selected.some(function (item) { return item.companyBranch.uuid !== selected[0].companyBranch.uuid; })) {
                this.snackBar.open(this.translateService.instant("ORDERS.MESSAGES.GENERATE_ERROR_WRONG_COMPANY_BRANCH"), "", { duration: 5000 });
            }
            else {
                this.matDialog.open(this.taskForms[selected[0].orderType], {
                    panelClass: "task-editor-dialog",
                    autoFocus: false,
                    disableClose: true,
                    data: {
                        mode: "add",
                        orders: selected,
                    },
                });
                this.clearSelect();
            }
        }
        else {
            this.snackBar.open(this.translateService.instant("ORDERS.MESSAGES.GENERATE_ERROR"), "", { duration: 5000 });
        }
    };
    OrdersListComponent.prototype.clearSelect = function () {
        this.selection = new SelectionModel(true, []);
    };
    OrdersListComponent.prototype.clearSearch = function () {
        if (this.searchOrder.value) {
            this.searchOrder.setValue("");
        }
    };
    OrdersListComponent.prototype.getListFilters = function (filters) {
        if (filters["taskDate"]) {
            filters["dateFrom"] = filters["taskDate"];
            filters["dateTo"] = filters["taskDate"];
            delete filters["taskDate"];
            this.companyBranches = this.companyBranches.filter(function (item) { return item.value !== "unallocated"; });
            this.filterByTaskDate = true;
            this.selection.clear();
        }
        else {
            this.filterByTaskDate = false;
            if (this.companyBranches.length > 0 &&
                !this.companyBranches.find(function (item) { return item.value === "unallocated"; })) {
                this.companyBranches.push({
                    name: this.translateService.instant("ORDERS.UNALLOCATED"),
                    value: "unallocated",
                });
            }
        }
        this.listFilters = filters;
        this.requestParams.page = 1;
        this.disableAnimations = true;
        this.getElementsList();
    };
    OrdersListComponent.prototype.createSearchFormControl = function () {
        var _this = this;
        this.searchOrder = new FormControl("");
        this.searchOrder.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.requestParams.search = value;
            _this.requestParams.page = 1;
            _this.disableAnimations = true;
            _this.getElementsList();
        });
    };
    OrdersListComponent.prototype.getQueryParams = function () {
        return Object.assign({}, this.requestParams, this.listFilters);
    };
    return OrdersListComponent;
}(List));
export { OrdersListComponent };
