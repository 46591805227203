var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatDialog, MatDialogRef, MatSnackBar, MatStepper, } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { CompaniesService } from "app/services/companies.service";
import * as moment from "moment";
import { of, zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { FuseTranslationLoaderService } from "../../../../../../@fuse/services/translation-loader.service";
import { AuthService } from "../../../../../services/auth.service";
import { CompanyBranchesService } from "../../../../../services/company-branches.service";
import { CompanyCarsService } from "../../../../../services/company-cars.service";
import { FilteredListsService } from "../../../../../services/filtered-lists.service";
import { OrdersService } from "../../../../../services/orders.service";
import { TasksService } from "../../../../../services/tasks.service";
import { UsersService } from "../../../../../services/users.service";
import { OrderTypes } from "../../../../../shared/consts/orders";
import { EndTimeOptions, EndTimeOptionsReceiving, StartTimeOptions, StartTimeOptionsReceiving, } from "../../../../../shared/consts/tasks";
import { ItemsOrigins } from "../../../../../shared/models/history.model";
import { OrderReceivingSubform, TaskReceiving, TaskStatuses, TaskTypes, } from "../../../../../shared/models/tasks.model";
import { UserTypes } from "../../../../../shared/models/users.model";
import { getDaysInMonth } from "../../../../../shared/utils/custom-date-formatter";
import { markFormGroupTouched } from "../../../../../shared/utils/markFormGroupAsTouched";
import { mapDatetimeToUtc, mapOrderDataApiValues, mapOrderDataToUuid, } from "../../../../../shared/utils/tasks-map";
import { ValidateItemsList } from "../../../../../shared/validators/itemsList.validator";
import { StartEndDateValidator } from "../../../../../shared/validators/startEndDate.validator";
import { locale as english } from "../../../../../translations/en";
import { locale as polish } from "../../../../../translations/pl";
import { CompanyEditorComponent } from "../../../../companies/components/company-editor/company-editor.component";
import { ConfirmModalComponent } from "../../../../shared/components/confirm-modal/confirm-modal.component";
import { HistoryDialogComponent } from "../../../../shared/components/history-dialog/history-dialog.component";
var TaskReceivingFormComponent = /** @class */ (function () {
    function TaskReceivingFormComponent(taskEditorDialogRef, data, matDialog, authService, tasksService, ordersService, filteredListsService, companyBranchesService, companiesService, companyCarsService, usersService, translateService, snackBar, formBuilder, _fuseTranslationLoaderService) {
        var _this = this;
        this.taskEditorDialogRef = taskEditorDialogRef;
        this.data = data;
        this.matDialog = matDialog;
        this.authService = authService;
        this.tasksService = tasksService;
        this.ordersService = ordersService;
        this.filteredListsService = filteredListsService;
        this.companyBranchesService = companyBranchesService;
        this.companiesService = companiesService;
        this.companyCarsService = companyCarsService;
        this.usersService = usersService;
        this.translateService = translateService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.isExpanded = [];
        this.customerCompanyBranches = {};
        this.taskStatuses = TaskStatuses;
        this.taskTypes = TaskTypes;
        this.userTypes = UserTypes;
        this.transportOptions = [];
        this.customerCompanyOptions = [];
        this.companyBranches = [];
        this.ordersOptions = [];
        this.ordersOptionsInitial = [];
        this.ordersOptionsRemoved = [];
        this.forwarderCompanies = [];
        this.companyCars = [];
        this.startTimeOptions = StartTimeOptions;
        this.endTimeOptions = EndTimeOptions;
        this.isLoading = false;
        this.startTimeOptionsReceiving = StartTimeOptionsReceiving;
        this.endTimeOptionsReceiving = EndTimeOptionsReceiving;
        this.filterParams = {
            page: 1,
            limit: 20,
            sort: "name",
            order: "asc",
        };
        this.filterParamsFullList = {
            page: 1,
            limit: 1000,
            sort: "name",
            order: "asc",
            active: "true",
        };
        this.filterOrdersParams = {
            page: 1,
            limit: 20,
            sort: "createdDate",
            order: "asc",
            available: true,
            orderType: this.taskTypes.Receiving,
            task: false,
            salesAccepted: true,
        };
        this.filterForwarderParams = {
            page: 1,
            limit: 20,
            sort: "createdDate",
            order: "asc",
            companyType: "forwarder",
        };
        this.availableHoursFromDisabled = function (i, timeIndex) {
            var control = _this.taskReceivingForm.controls.taskData;
            return (control.controls[i].get("availableHoursTo").value &&
                _this.startTimeOptionsReceiving.includes(control.controls[i].get("availableHoursTo").value) &&
                timeIndex + 1 >
                    _this.startTimeOptionsReceiving.indexOf(control.controls[i].get("availableHoursTo").value));
        };
        this.availableHoursToDisabled = function (i, timeIndex) {
            var control = _this.taskReceivingForm.controls.taskData;
            return (control.controls[i].get("availableHoursFrom").value &&
                timeIndex <
                    _this.startTimeOptionsReceiving.indexOf(control.controls[i].get("availableHoursFrom").value));
        };
        this.user = this.authService.user.user;
        this.mode = data.mode;
        this.taskId = data.taskId || data.taskNumber;
        this._fuseTranslationLoaderService.loadTranslations(polish, english);
        this.indexStep = data.indexStep || 0;
    }
    TaskReceivingFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createSearchFilters();
        this.createForm();
        if (this.mode === "edit" || this.mode === "view" || this.mode === "clone") {
            this.tasksService
                .getTask(this.taskId)
                .pipe(map(function (res) {
                _this.task = res;
                _this.taskId = res.uuid;
                res.taskData.forEach(function (item) {
                    if (item.order && item.order.uuid) {
                        _this.ordersOptionsInitial.push(item.order.uuid);
                    }
                });
                _this.createForm(_this.task);
                _this.getPdfNeededData();
            }), switchMap(function () {
                return zip(_this.filteredListsService.getTransportList(_this.filterParamsFullList), _this.filteredListsService.getCompaniesList(_this.filterParams), _this.usersService.getCompanyBranchList(), _this.filteredListsService.getOrdersList(_this.filterOrdersParams), _this.filteredListsService.getCompaniesList(_this.filterForwarderParams));
            }))
                .subscribe(function (_a) {
                var transportRes = _a[0], companiesRes = _a[1], branchesRes = _a[2], ordersRes = _a[3], forwarderRes = _a[4];
                _this.transportOptions = transportRes.transports;
                _this.customerCompanyOptions = companiesRes.customerCompanies;
                _this.companyBranches = branchesRes.branches;
                _this.ordersOptions = ordersRes.orders;
                _this.forwarderCompanies = forwarderRes.customerCompanies;
            });
        }
        else {
            this.todayDate = new Date();
            zip(this.filteredListsService.getTransportList(this.filterParamsFullList), this.filteredListsService.getCompaniesList(this.filterParams), this.filteredListsService.getOrdersList(this.filterOrdersParams), this.usersService.getCompanyBranchList(), this.filteredListsService.getCompaniesList(this.filterForwarderParams)).subscribe(function (_a) {
                var transportRes = _a[0], companiessRes = _a[1], ordersRes = _a[2], branchesRes = _a[3], forwarderRes = _a[4];
                _this.transportOptions = transportRes.transports;
                _this.customerCompanyOptions = companiessRes.customerCompanies;
                _this.ordersOptions = ordersRes.orders;
                _this.companyBranches = branchesRes.branches;
                _this.forwarderCompanies = forwarderRes.customerCompanies;
                if (_this.data.orders && _this.data.orders.length) {
                    _this.createForm(_this.data.orders[0]);
                    _this.data.orders.forEach(function (item, index) {
                        _this.addOrderSubform(index, item, true);
                    });
                }
            });
        }
    };
    TaskReceivingFormComponent.prototype.setRangeEndDateMax = function (date) {
        this.rangeDateEndMin = new Date(this.taskReceivingForm.get("startDate").value || this.todayDate);
        this.rangeDateEndMax = new Date(date || this.todayDate);
        this.rangeDateEndMax.setMonth(this.rangeDateEndMax.getMonth() !== 11
            ? this.rangeDateEndMax.getMonth() + 1
            : 0);
        if (this.rangeDateEndMax.getMonth() === 0) {
            this.rangeDateEndMax.setFullYear(this.rangeDateEndMax.getFullYear() + 1);
        }
        this.rangeDateEndMax.setDate(getDaysInMonth(this.rangeDateEndMax.getFullYear(), this.rangeDateEndMax.getMonth()));
    };
    TaskReceivingFormComponent.prototype.submitTask = function () {
        var _this = this;
        this.isLoading = true;
        markFormGroupTouched(this.taskReceivingForm);
        if (this.taskReceivingForm.invalid) {
            this.isLoading = false;
            return;
        }
        var taskForm = this.taskReceivingForm.getRawValue();
        var taskData = taskForm.taskData.map(function (item) {
            item = Object.assign({}, item);
            var id = item.customerCompanyBranch;
            item.customerCompanyBranch = Object.assign({}, item.customerCompanyBranchOther, { uuid: id === "other" ? null : id });
            item.customerCompanyBranch.email =
                item.customerCompanyBranch.email || null;
            item.suggestedDate = item.suggestedDate || null;
            item.order.comment = item.customerCompanyBranchOther.order_comment;
            delete item.customerCompanyBranchOther;
            return item;
        });
        this.createNewOrders(taskData, taskForm.companyBranchId).subscribe(function (taskDataFromOther) {
            taskData = taskData.filter(function (item) { return item.uuid !== "other"; });
            taskDataFromOther.forEach(function (item) {
                taskData.push(item);
            });
            var newTask = {
                name: taskForm.name,
                companyBranchId: taskForm.companyBranchId,
                transportId: taskForm.transport ? taskForm.transport.uuid : null,
                startDate: mapDatetimeToUtc(taskForm.startDate, taskForm.startTime),
                endDate: mapDatetimeToUtc(taskForm.endDate, taskForm.endTime),
                taskType: taskForm.taskType,
                comment: taskForm.comment,
                referenceNumber: taskForm.referenceNumber,
                emailService: taskForm.emailService,
                registrationNumFront: taskForm.forwarderRegistrationNumberFront,
                registrationNumBack: taskForm.forwarderRegistrationNumberBack,
                taskData: _this.mapTaskData(taskData),
                forwarderId: !!taskForm.forwarderCompanyName
                    ? taskForm.forwarderId
                    : null,
                forwarderCompanyName: taskForm.forwarderCompanyName,
                forwarderDriverName: taskForm.forwarderDriverName,
                forwarderDriverPhone: taskForm.forwarderDriverPhone,
                forwarderRegistrationNumberFront: taskForm.forwarderRegistrationNumberFront,
                forwarderRegistrationNumberBack: taskForm.forwarderRegistrationNumberBack,
                forwarderKilometers: Number(taskForm.forwarderKilometers.replace(",", ".")),
                forwarderRouteDescription: taskForm.forwarderRouteDescription,
                forwarderBdo: taskForm.forwarderBdo,
                forwarderCarName: taskForm.forwarderCarName,
                forwarderCarType: taskForm.forwarderCarType,
                forwarderExpense: Number(taskForm.forwarderExpense.replace(",", ".")),
                forwarderInvoiceNumber: taskForm.forwarderInvoiceNumber,
            };
            if (_this.mode === "add" || _this.mode === "clone") {
                _this.createTask(newTask);
            }
            else {
                var ordersToDelete_1 = [];
                var oldOrdersIds = _this.task && _this.task.taskData
                    ? _this.task.taskData.map(function (item) {
                        if (item.order && item.order.uuid) {
                            return item.order.uuid;
                        }
                    })
                    : [];
                var newOrdersIds_1 = newTask.taskData
                    ? newTask.taskData.map(function (item) {
                        if (item.orderId) {
                            return item.orderId;
                        }
                    })
                    : [];
                oldOrdersIds.forEach(function (oldItem) {
                    if (oldItem && !newOrdersIds_1.includes(oldItem)) {
                        ordersToDelete_1.push(oldItem);
                    }
                });
                zip(_this.tasksService.updateTask(_this.taskId, newTask), _this.tasksService.updateTaskOrders(_this.taskId, ordersToDelete_1)).subscribe(function () {
                    _this.taskEditorDialogRef.close();
                    _this.taskEditorDialogRef.afterClosed().subscribe(function () {
                        _this.tasksService.onEditEvent.emit();
                        _this.ordersService.onEditEvent.emit();
                    });
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.UPDATE_SUCCESS"), "", { duration: 5000 });
                }, function () {
                    _this.isLoading = false;
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.UPDATE_ERROR"), "", { duration: 5000 });
                });
            }
        });
    };
    TaskReceivingFormComponent.prototype.createTask = function (newTask) {
        var _this = this;
        var taskForm = this.taskReceivingForm.getRawValue();
        if (taskForm.recurring) {
            var recurringTask = {
                intervalType: "WEEK",
                intervalValue: taskForm.recurringInfo.intervalValue,
                rangeDateEnd: moment(taskForm.recurringInfo.rangeDateEnd).format("YYYY-MM-DD"),
                weekdays: taskForm.recurringInfo.weekdays,
                task: newTask,
            };
            this.tasksService.createRecurringTask(recurringTask).subscribe(function () {
                _this.taskEditorDialogRef.close();
                _this.taskEditorDialogRef.afterClosed().subscribe(function () {
                    _this.tasksService.onEditEvent.emit();
                    _this.ordersService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.CREATE_SUCCESS"), "", { duration: 5000 });
            }, function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.CREATE_ERROR"), "", { duration: 5000 });
            });
        }
        else {
            this.tasksService.createTask(newTask).subscribe(function () {
                _this.taskEditorDialogRef.close();
                _this.taskEditorDialogRef.afterClosed().subscribe(function () {
                    _this.tasksService.onEditEvent.emit();
                    _this.ordersService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.CREATE_SUCCESS"), "", { duration: 5000 });
            }, function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.CREATE_ERROR"), "", { duration: 5000 });
            });
        }
    };
    TaskReceivingFormComponent.prototype.deleteTask = function () {
        var _this = this;
        var deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false,
        });
        deleteDialogRef.componentInstance.confirmMessage =
            this.translateService.instant("TASKS.MESSAGES.DELETE_QUESTION");
        deleteDialogRef.componentInstance.titleMessage =
            this.translateService.instant("TASKS.MESSAGES.DELETE_TASK_TITLE");
        deleteDialogRef.componentInstance.confirmButton =
            this.translateService.instant("TASKS.DELETE");
        deleteDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                var ordersToDelete_2 = [];
                _this.task &&
                    _this.task.taskData &&
                    _this.task.taskData.forEach(function (item) {
                        if (item.order && item.order.uuid) {
                            ordersToDelete_2.push(item.order.uuid);
                        }
                    });
                zip(_this.tasksService.deleteTask(_this.taskId), _this.tasksService.updateTaskOrders(_this.taskId, ordersToDelete_2)).subscribe(function () {
                    _this.taskEditorDialogRef.close();
                    _this.taskEditorDialogRef.afterClosed().subscribe(function () {
                        _this.tasksService.onEditEvent.emit();
                        _this.ordersService.onEditEvent.emit();
                    });
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.DELETE_SUCCESS"), "", { duration: 5000 });
                }, function () {
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.DELETE_ERROR"), "", { duration: 5000 });
                });
            }
        });
    };
    TaskReceivingFormComponent.prototype.completeTask = function () {
        var _this = this;
        var completeDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false,
        });
        completeDialogRef.componentInstance.confirmMessage =
            this.translateService.instant("TASKS.MESSAGES.COMPLETE_QUESTION");
        completeDialogRef.componentInstance.titleMessage =
            this.translateService.instant("TASKS.MESSAGES.COMPLETE_TASK_TITLE");
        completeDialogRef.componentInstance.confirmButton =
            this.translateService.instant("TASKS.COMPLETE_TASK");
        completeDialogRef.componentInstance.skipButton =
            this.translateService.instant("TASKS.COMPLETE_SKIP");
        completeDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.tasksService.completeTask(_this.taskId).subscribe(function () {
                    _this.taskEditorDialogRef.close();
                    _this.tasksService.onEditEvent.emit();
                    _this.ordersService.onEditEvent.emit();
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.COMPLETE_SUCCESS"), "", { duration: 5000 });
                }, function () {
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.COMPLETE_ERROR"), "", { duration: 5000 });
                });
            }
        });
    };
    TaskReceivingFormComponent.prototype.cancelTask = function () {
        var _this = this;
        var cancelDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false,
        });
        cancelDialogRef.componentInstance.confirmMessage =
            this.translateService.instant("TASKS.MESSAGES.CANCEL_QUESTION");
        cancelDialogRef.componentInstance.titleMessage =
            this.translateService.instant("TASKS.MESSAGES.CANCEL_TASK_TITLE");
        cancelDialogRef.componentInstance.confirmButton =
            this.translateService.instant("TASKS.CANCEL_TASK");
        cancelDialogRef.componentInstance.skipButton =
            this.translateService.instant("TASKS.CANCEL_SKIP");
        cancelDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.tasksService.cancelTask(_this.taskId).subscribe(function () {
                    _this.taskEditorDialogRef.close();
                    _this.tasksService.onEditEvent.emit();
                    _this.ordersService.onEditEvent.emit();
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.CANCEL_SUCCESS"), "", { duration: 5000 });
                }, function () {
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.CANCEL_ERROR"), "", { duration: 5000 });
                });
            }
        });
    };
    TaskReceivingFormComponent.prototype.setStartDate = function (newDate) {
        this.taskReceivingForm.patchValue({ endDate: new Date(newDate) });
        this.setRangeEndDateMax(newDate);
        this.handleRecurringChange();
    };
    TaskReceivingFormComponent.prototype.handleRecurringChange = function () {
        var _a;
        var weekdays = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        var date = this.taskReceivingForm.get("startDate").value || new Date();
        this.taskReceivingForm.get("recurringInfo").get("weekdays").patchValue({
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
            Sunday: false,
        });
        this.taskReceivingForm
            .get("recurringInfo")
            .get("weekdays")
            .patchValue((_a = {},
            _a[weekdays[moment(date).day()]] = true,
            _a));
    };
    TaskReceivingFormComponent.prototype.addOrderSubform = function (index, orderItem, generatedFromOrderId) {
        var _this = this;
        if (orderItem === void 0) { orderItem = new OrderReceivingSubform(); }
        if (generatedFromOrderId === void 0) { generatedFromOrderId = false; }
        if (orderItem.customerCompany && orderItem.customerCompany.uuid) {
            this.getCustomerCompanyBranches(orderItem.customerCompany.uuid);
        }
        Object.keys(this.isExpanded).forEach(function (item) { return (_this.isExpanded[item] = false); });
        this.isExpanded.push(!orderItem.orderData);
        orderItem.orderData = mapOrderDataToUuid(orderItem.orderData);
        this.orderFilter();
        var control = this.taskReceivingForm.controls.taskData;
        control.push(this.formBuilder.group({
            uuid: new FormControl(this.mode === "clone"
                ? "other"
                : (generatedFromOrderId && orderItem.uuid) ||
                    (orderItem.order && orderItem.order.uuid) ||
                    orderItem.orderId ||
                    "other"),
            order: new FormControl(this.mode === "clone"
                ? { uuid: "other" }
                : (generatedFromOrderId && orderItem) ||
                    orderItem.order || { uuid: "other" }),
            customerCompany: new FormControl(orderItem.customerCompany || null, Validators.required),
            customerCompanyBranch: new FormControl(orderItem.customerCompanyBranch
                ? orderItem.customerCompanyBranch.uuid
                    ? orderItem.customerCompanyBranch.uuid
                    : "other"
                : null, Validators.required),
            orderData: new FormControl(orderItem.orderData || [], [
                Validators.required,
                ValidateItemsList,
            ]),
            referenceNumber: new FormControl(this.mode === "clone" ? null : orderItem.referenceNumber || null),
            salesAccepted: new FormControl((orderItem.order && orderItem.order.salesAccepted) ||
                orderItem.salesAccepted ||
                false, Validators.required),
            suggestedDate: new FormControl(this.mode === "clone"
                ? null
                : (orderItem.order && orderItem.order.suggestedDate) ||
                    orderItem.suggestedDate ||
                    null, Validators.required),
            availableHoursFrom: new FormControl((orderItem.order && orderItem.order.availableHoursFrom) ||
                orderItem.availableHoursFrom ||
                null),
            availableHoursTo: new FormControl((orderItem.order && orderItem.order.availableHoursTo) ||
                orderItem.availableHoursTo ||
                null),
            companyBranchId: new FormControl((this.task &&
                this.task.companyBranch &&
                this.task.companyBranch.uuid) ||
                (orderItem.companyBranch && orderItem.companyBranch.uuid) ||
                null, Validators.required),
            bdo: new FormControl(orderItem.bdo || null, Validators.required),
            bdoFree: new FormControl(orderItem.bdoFree),
            bdoPeopleCollection: new FormControl(orderItem.bdoPeopleCollection),
            kpoEmails: new FormControl(orderItem.kpoEmails ||
                (orderItem.order && orderItem.order.kpoEmails) ||
                null),
            reinvoice: new FormControl(orderItem.reinvoice || false),
            price: new FormControl(orderItem.price ? String(orderItem.price).replace(".", ",") : ""),
            reinvoiceNumber: new FormControl(orderItem.reinvoiceNumber || ""),
            invoiceComment: new FormControl(orderItem.invoiceComment || ""),
            customerCompanyBranchOther: new FormGroup({
                name: new FormControl((orderItem.customerCompanyBranch &&
                    orderItem.customerCompanyBranch.name) ||
                    null, orderItem.customerCompanyBranch &&
                    orderItem.customerCompanyBranch.name
                    ? Validators.required
                    : null),
                street: new FormControl((orderItem.customerCompanyBranch &&
                    orderItem.customerCompanyBranch.street) ||
                    null, Validators.required),
                postalCode: new FormControl((orderItem.customerCompanyBranch &&
                    orderItem.customerCompanyBranch.postalCode) ||
                    null, Validators.required),
                city: new FormControl((orderItem.customerCompanyBranch &&
                    orderItem.customerCompanyBranch.city) ||
                    null, Validators.required),
                country: new FormControl((orderItem.customerCompanyBranch &&
                    orderItem.customerCompanyBranch.country) ||
                    "Polska", Validators.required),
                contact: new FormControl((orderItem.customerCompanyBranch &&
                    orderItem.customerCompanyBranch.contact) ||
                    null),
                email: new FormControl((orderItem.customerCompanyBranch &&
                    orderItem.customerCompanyBranch.email) ||
                    null),
                phone: new FormControl((orderItem.customerCompanyBranch &&
                    orderItem.customerCompanyBranch.phone) ||
                    null, Validators.required),
                note: new FormControl((orderItem.customerCompanyBranch &&
                    orderItem.customerCompanyBranch.note) ||
                    null),
                order_comment: new FormControl(orderItem.comment ||
                    (orderItem.order && orderItem.order.comment) ||
                    null),
                mainBranch: new FormControl((orderItem.customerCompanyBranch &&
                    orderItem.customerCompanyBranch.mainBranch) ||
                    false),
            }),
        }));
        generatedFromOrderId && control.at(index).get("order").disable();
        generatedFromOrderId &&
            setTimeout(function () {
                if (control.at(index).get("companyBranchId").value !== null &&
                    _this.data &&
                    _this.data.orders &&
                    _this.data.orders.length &&
                    _this.data.orders[0].companyBranchId !== null) {
                    _this.taskReceivingForm.get("companyBranchId").disable();
                }
            }, 0);
        this.updateBdo(orderItem.bdo, index);
        control.at(index).get("salesAccepted").disable();
        if (control.at(index).get("companyBranchId").value !== null) {
            control.at(index).get("companyBranchId").disable();
        }
        if (control.at(index).get("reinvoice").value === false) {
            control.at(index).get("price").disable();
            control.at(index).get("reinvoiceNumber").disable();
        }
    };
    TaskReceivingFormComponent.prototype.deleteOrderSubform = function (index) {
        var _this = this;
        this.isExpanded.splice(index, 1);
        var control = this.taskReceivingForm.controls.taskData;
        var order = control.value[index];
        if (order.uuid !== "other" &&
            this.ordersOptionsInitial.includes(order.uuid)) {
            this.ordersService.getOrder(order.uuid).subscribe(function (res) {
                _this.ordersOptionsRemoved.push(res);
            });
        }
        control.removeAt(index);
    };
    TaskReceivingFormComponent.prototype.pickOrder = function (order, index) {
        var _this = this;
        var control = this.taskReceivingForm.controls.taskData;
        var previousOrder = control.value[index];
        if (order.customerCompany && order.customerCompany.uuid) {
            this.getCustomerCompanyBranches(order.customerCompany.uuid);
        }
        else {
            control.controls[index].get("customerCompanyBranch").disable();
        }
        order.orderData = mapOrderDataToUuid(order.orderData);
        if (order.uuid === "other") {
            control.controls[index].reset();
            control.controls[index].patchValue({
                uuid: "other",
                order: { uuid: "other" },
                orderData: [],
                customerCompanyBranchOther: {
                    country: "Polska",
                    mainBranch: false,
                },
            });
        }
        else {
            var CCBranch = order.customerCompanyBranch && order.customerCompanyBranch.uuid;
            if (order.customerCompanyBranch &&
                !order.customerCompanyBranch.uuid &&
                order.customerCompanyBranch.street) {
                CCBranch = "other";
            }
            control.controls[index].patchValue({
                uuid: order.uuid || "other",
                order: order || { uuid: "other" },
                customerCompany: order.customerCompany || null,
                referenceNumber: order.referenceNumber || null,
                bdo: order.bdo || null,
                bdoFree: order.bdoFree,
                bdoPeopleCollection: order.bdoPeopleCollection,
                reinvoice: order.reinvoice,
                kpoEmails: order.kpoEmails,
                price: order.price ? String(order.price).replace(".", ",") : "",
                reinvoiceNumber: order.reinvoiceNumber,
                invoiceComment: order.invoiceComment,
                availableHoursTo: order.availableHoursFrom || null,
                availableHoursFrom: order.availableHoursFrom || null,
                suggestedDate: order.suggestedDate,
                companyBranchId: order.companyBranch.uuid || null,
                salesAccepted: order.salesAccepted,
                orderData: order.orderData || [],
                customerCompanyBranch: CCBranch,
                customerCompanyBranchOther: {
                    name: (order.customerCompanyBranch && order.customerCompanyBranch.name) ||
                        null,
                    street: (order.customerCompanyBranch &&
                        order.customerCompanyBranch.street) ||
                        null,
                    postalCode: (order.customerCompanyBranch &&
                        order.customerCompanyBranch.postalCode) ||
                        null,
                    city: (order.customerCompanyBranch && order.customerCompanyBranch.city) ||
                        null,
                    country: (order.customerCompanyBranch &&
                        order.customerCompanyBranch.country) ||
                        "Polska",
                    contact: (order.customerCompanyBranch &&
                        order.customerCompanyBranch.contact) ||
                        null,
                    email: (order.customerCompanyBranch &&
                        order.customerCompanyBranch.email) ||
                        null,
                    phone: (order.customerCompanyBranch &&
                        order.customerCompanyBranch.phone) ||
                        null,
                    note: (order.customerCompanyBranch && order.customerCompanyBranch.note) ||
                        null,
                    order_comment: order.comment || null,
                    mainBranch: false,
                },
            });
        }
        if (order.uuid !== "other") {
            this.ordersOptionsRemoved = this.ordersOptionsRemoved.filter(function (item) { return item.uuid !== order.uuid; });
        }
        if (previousOrder &&
            this.ordersOptionsInitial.includes(previousOrder.uuid)) {
            this.ordersService.getOrder(previousOrder.uuid).subscribe(function (res) {
                _this.ordersOptionsRemoved.push(res);
            });
        }
        this.updateBdo(order.bdo, index);
    };
    TaskReceivingFormComponent.prototype.onChangeCustomerCompany = function (company, index) {
        return __awaiter(this, void 0, void 0, function () {
            var control, ccMainBranch;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(company && company.uuid)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getCustomerCompanyBranches(company.uuid)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        control = this.taskReceivingForm.controls.taskData;
                        if (this.taskReceivingForm.get("taskData").value[index].uuid !== "other") {
                            control.controls[index].patchValue({
                                customerCompany: company,
                                uuid: "other",
                                order: { uuid: "other" },
                                orderData: [],
                            });
                        }
                        ccMainBranch = this.customerCompanyBranches[company && company.uuid] &&
                            this.customerCompanyBranches[company && company.uuid][0];
                        control.controls[index].patchValue({
                            customerCompany: company,
                            kpoEmails: company.kpo.map(function (kpo) { return kpo.email; }).join(","),
                            customerCompanyBranch: ccMainBranch ? ccMainBranch.uuid || "other" : null,
                            customerCompanyBranchOther: {
                                name: (ccMainBranch && ccMainBranch.name) || null,
                                street: (ccMainBranch && ccMainBranch.street) || null,
                                postalCode: (ccMainBranch && ccMainBranch.postalCode) || null,
                                city: (ccMainBranch && ccMainBranch.city) || null,
                                country: (ccMainBranch && ccMainBranch.country) || "Polska",
                                contact: (ccMainBranch && ccMainBranch.contact) || null,
                                email: (ccMainBranch && ccMainBranch.email) || null,
                                phone: (ccMainBranch && ccMainBranch.phone) || null,
                                note: (ccMainBranch && ccMainBranch.note) || null,
                                mainBranch: false,
                            },
                        });
                        this.updateBdo(company.bdo, index, true);
                        return [2 /*return*/];
                }
            });
        });
    };
    TaskReceivingFormComponent.prototype.compareObjectsByUuid = function (val1, val2) {
        return val1 && val2 && val1.uuid && val2.uuid && val1.uuid === val2.uuid;
    };
    TaskReceivingFormComponent.prototype.addCustomerCompany = function (index) {
        var _this = this;
        var editorDialogRef = this.matDialog.open(CompanyEditorComponent, {
            panelClass: "company-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "add",
            },
        });
        editorDialogRef.componentInstance.onAddCompany.subscribe(function (res) {
            _this.customerCompanyOptions.push(res);
            _this.onChangeCustomerCompany(res, index);
            _this.companySelect.close();
        });
    };
    TaskReceivingFormComponent.prototype.getTitle = function () {
        switch (this.mode) {
            case "add":
            case "clone":
                return this.translateService.instant("TASKS.TITLE_ADD");
            case "edit":
                return this.translateService.instant("TASKS.TITLE_EDIT");
            case "view":
                return this.translateService.instant("TASKS.TITLE_VIEW");
            default:
                return;
        }
    };
    TaskReceivingFormComponent.prototype.showHistory = function () {
        this.matDialog.open(HistoryDialogComponent, {
            panelClass: "history-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                item: this.task,
                itemOrigin: ItemsOrigins.Task,
                itemId: this.taskId,
                itemNumber: this.task.taskNumber,
            },
        });
    };
    TaskReceivingFormComponent.prototype.enableEditMode = function () {
        this.indexStep = this.stepper.selectedIndex;
        this.taskEditorDialogRef.close();
        this.matDialog.open(TaskReceivingFormComponent, {
            panelClass: "task-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "edit",
                taskId: this.taskId,
                indexStep: this.indexStep,
            },
        });
    };
    TaskReceivingFormComponent.prototype.enableCloneMode = function () {
        this.indexStep = this.stepper.selectedIndex;
        this.taskEditorDialogRef.close();
        this.matDialog.open(TaskReceivingFormComponent, {
            panelClass: "task-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "clone",
                taskId: this.taskId,
                indexStep: this.indexStep,
            },
        });
    };
    TaskReceivingFormComponent.prototype.getOrdersOptions = function (index) {
        var options = this.ordersOptions.concat(this.ordersOptionsRemoved);
        var selectedOrder = this.taskReceivingForm.get("taskData").value[index];
        this.taskReceivingForm.get("taskData").value &&
            this.taskReceivingForm.get("taskData").value.forEach(function (chosenOrder) {
                if (chosenOrder.uuid &&
                    chosenOrder.uuid !== "other" &&
                    options.findIndex(function (item) { return item.uuid === chosenOrder.uuid; }) > -1 &&
                    chosenOrder.uuid !== selectedOrder.uuid) {
                    options.splice(options.findIndex(function (item) { return item.uuid === chosenOrder.uuid; }), 1);
                }
            });
        if (selectedOrder.uuid &&
            options.findIndex(function (order) { return order.uuid === selectedOrder.uuid; }) === -1 &&
            selectedOrder.uuid !== "other") {
            options.push(selectedOrder.order || selectedOrder);
        }
        return options;
    };
    TaskReceivingFormComponent.prototype.getCustomerCompaniesOptions = function (index) {
        var options = this.customerCompanyOptions.slice();
        var selectedOrder = this.taskReceivingForm.get("taskData").value[index];
        if (selectedOrder.customerCompany &&
            selectedOrder.customerCompany.uuid &&
            options.findIndex(function (item) { return item.uuid === selectedOrder.customerCompany.uuid; }) === -1) {
            options.push(selectedOrder.customerCompany);
        }
        return options;
    };
    TaskReceivingFormComponent.prototype.getTransportOptions = function () {
        var options = this.transportOptions.slice();
        var chosenTransport = this.taskReceivingForm.get("transport").value;
        if (chosenTransport &&
            options.findIndex(function (item) { return item.uuid === chosenTransport.uuid; }) === -1) {
            options.push(chosenTransport);
        }
        return options;
    };
    TaskReceivingFormComponent.prototype.openEmailClient = function (index) {
        var taskData = this.taskReceivingForm.getRawValue();
        if (taskData.taskData[index].customerCompanyBranchOther.email) {
            return "mailto:" + taskData.taskData[index].customerCompanyBranchOther.email;
        }
    };
    TaskReceivingFormComponent.prototype.canSendEmail = function (index) {
        return (this.mode === "view" &&
            this.taskReceivingForm.getRawValue().taskData[index]
                .customerCompanyBranchOther.email);
    };
    TaskReceivingFormComponent.prototype.reinvoiceChange = function (reinvoiceChecked, orderIndex) {
        var control = this.taskReceivingForm.controls.taskData;
        if (reinvoiceChecked) {
            control.controls[orderIndex].get("price").enable();
            control.controls[orderIndex].get("reinvoiceNumber").enable();
        }
        else {
            control.controls[orderIndex].get("price").setValue(null);
            control.controls[orderIndex].get("price").disable();
            control.controls[orderIndex].get("reinvoiceNumber").setValue("");
            control.controls[orderIndex].get("reinvoiceNumber").disable();
        }
    };
    TaskReceivingFormComponent.prototype.bdoRequiredToggle = function (bdoFree, orderIndex) {
        var control = this.taskReceivingForm.controls.taskData;
        var pickedOrder = control.controls[orderIndex].get("order").value;
        var pickedCustomerCompany = control.controls[orderIndex].get("customerCompany").value;
        var defaultBdo = (pickedOrder && pickedOrder.bdo) ||
            (pickedCustomerCompany && pickedCustomerCompany.bdo) ||
            null;
        if (bdoFree) {
            control.controls[orderIndex].get("bdo").clearValidators();
            control.controls[orderIndex].get("bdo").disable();
            control.controls[orderIndex].get("bdo").setValue("");
            control.controls[orderIndex].get("bdoPeopleCollection").setValue(false);
        }
        else if (!bdoFree && defaultBdo) {
            control.controls[orderIndex]
                .get("bdo")
                .setValidators(Validators.required);
            control.controls[orderIndex].get("bdo").disable();
            control.controls[orderIndex].get("bdo").setValue(defaultBdo);
        }
        else if (!bdoFree && !defaultBdo) {
            control.controls[orderIndex]
                .get("bdo")
                .setValidators(Validators.required);
            control.controls[orderIndex].get("bdo").enable();
        }
    };
    TaskReceivingFormComponent.prototype.bdoPeopleCollectionToggle = function (bdoPeopleCollection, orderIndex) {
        var control = this.taskReceivingForm.controls.taskData;
        var pickedOrder = control.controls[orderIndex].get("order").value;
        var pickedCustomerCompany = control.controls[orderIndex].get("customerCompany").value;
        var defaultBdo = (pickedOrder && pickedOrder.bdo) ||
            (pickedCustomerCompany && pickedCustomerCompany.bdo) ||
            null;
        if (bdoPeopleCollection) {
            control.controls[orderIndex].get("bdo").clearValidators();
            control.controls[orderIndex].get("bdo").disable();
            control.controls[orderIndex].get("bdo").setValue("");
            control.controls[orderIndex].get("bdoFree").setValue(false);
        }
        else if (!bdoPeopleCollection && defaultBdo) {
            control.controls[orderIndex]
                .get("bdo")
                .setValidators(Validators.required);
            control.controls[orderIndex].get("bdo").disable();
            control.controls[orderIndex].get("bdo").setValue(defaultBdo);
        }
        else if (!bdoPeopleCollection && !defaultBdo) {
            control.controls[orderIndex]
                .get("bdo")
                .setValidators(Validators.required);
            control.controls[orderIndex].get("bdo").enable();
        }
    };
    TaskReceivingFormComponent.prototype.updateBdo = function (bdo, orderIndex, enableCheckbox) {
        if (enableCheckbox === void 0) { enableCheckbox = false; }
        var control = this.taskReceivingForm.controls.taskData;
        if (enableCheckbox) {
            control.controls[orderIndex].get("bdoFree").enable();
            control.controls[orderIndex].get("bdoPeopleCollection").enable();
        }
        var bdoFree = control.controls[orderIndex].get("bdoFree").value;
        var bdoPeopleCollection = control.controls[orderIndex].get("bdoPeopleCollection").value;
        control.controls[orderIndex].get("bdo").clearValidators();
        if (bdo && !bdoFree && !bdoPeopleCollection) {
            control.controls[orderIndex].get("bdo").setValue(bdo);
            control.controls[orderIndex].get("bdo").disable();
        }
        else if ((!bdo && !bdoFree) || !bdoPeopleCollection) {
            control.controls[orderIndex].get("bdo").setValue("");
            control.controls[orderIndex].get("bdo").enable();
        }
        else if (!bdo && (bdoFree || bdoPeopleCollection)) {
            control.controls[orderIndex].get("bdo").setValue("");
            control.controls[orderIndex].get("bdo").disable();
        }
    };
    TaskReceivingFormComponent.prototype.startEndDateTimezoneValid = function (start, end) {
        if (this.mode === "view") {
            //we need to display current values even if they are wrong in `view` mode
            if (start && !this.startTimeOptions.includes(start)) {
                this.startTimeOptions = [start].concat(this.startTimeOptions);
            }
            if (end && !this.endTimeOptions.includes(end)) {
                this.endTimeOptions = [end].concat(this.endTimeOptions);
            }
            return true;
        }
        else {
            // if at least one of current values is wrong we clear fields
            if (start && !this.startTimeOptions.includes(start)) {
                return false;
            }
            if (end && !this.endTimeOptions.includes(end)) {
                return false;
            }
            return true;
        }
    };
    TaskReceivingFormComponent.prototype.createForm = function (inputData) {
        var _this = this;
        if (inputData === void 0) { inputData = new TaskReceiving(); }
        inputData.startTime = inputData.startDate
            ? moment(inputData.startDate).format("HH:mm")
            : null;
        inputData.endTime = inputData.endDate
            ? moment(inputData.endDate).format("HH:mm")
            : null;
        if (!this.startEndDateTimezoneValid(inputData.startTime, inputData.endTime)) {
            inputData.startTime = null;
            inputData.endTime = null;
        }
        this.taskReceivingForm = this.formBuilder.group({
            name: new FormControl(inputData.name || null, Validators.required),
            companyBranchId: new FormControl((inputData.companyBranch && inputData.companyBranch.uuid) ||
                this.data.companyBranchId ||
                null, Validators.required),
            transport: new FormControl(inputData.transport || null, Validators.required),
            startDate: new FormControl(this.mode === "clone"
                ? null
                : inputData.startDate || this.data.startDate || null, Validators.required),
            startTime: new FormControl(inputData.startTime || this.data.startTime || null, Validators.required),
            endDate: new FormControl(this.mode === "clone"
                ? null
                : inputData.endDate || this.data.endDate || null, Validators.required),
            endTime: new FormControl(inputData.endTime || this.data.endTime || null, Validators.required),
            taskType: new FormControl(inputData.taskType || this.taskTypes.Receiving, Validators.required),
            comment: new FormControl(this.mode === "add" || this.mode === "clone"
                ? null
                : inputData.comment || null),
            referenceNumber: new FormControl(this.mode === "add" || this.mode === "clone"
                ? null
                : inputData.referenceNumber || null),
            emailService: new FormControl(this.mode === "add" || this.mode === "clone"
                ? true
                : inputData.emailService),
            registrationNumFront: new FormControl(this.mode === "add"
                ? null
                : inputData.forwarderRegistrationNumberFront
                    ? inputData.forwarderRegistrationNumberFront
                    : null),
            registrationNumBack: new FormControl(this.mode === "add"
                ? null
                : inputData.forwarderRegistrationNumberBack
                    ? inputData.forwarderRegistrationNumberBack
                    : null),
            taskData: this.formBuilder.array([], Validators.required),
            recurring: new FormControl(false),
            recurringInfo: this.formBuilder.group({
                intervalType: new FormControl("WEEK"),
                intervalValue: new FormControl(1, Validators.pattern(/\d/)),
                rangeDateEnd: new FormControl(inputData.startDate),
                weekdays: new FormGroup({
                    Monday: new FormControl(false),
                    Tuesday: new FormControl(false),
                    Wednesday: new FormControl(false),
                    Thursday: new FormControl(false),
                    Friday: new FormControl(false),
                    Saturday: new FormControl(false),
                    Sunday: new FormControl(false),
                }),
            }),
            forwarderId: new FormControl(inputData.forwarderId || ""),
            forwarderCompanyName: new FormControl(inputData.forwarderCompanyName || ""),
            originalCompanyName: new FormControl(inputData.originalCompanyName || ""),
            forwarderDriverName: new FormControl(inputData.forwarderDriverName || ""),
            forwarderDriverPhone: new FormControl(inputData.forwarderDriverPhone || ""),
            forwarderRegistrationNumberFront: new FormControl(inputData.forwarderRegistrationNumberFront || ""),
            forwarderRegistrationNumberBack: new FormControl(inputData.forwarderRegistrationNumberBack || ""),
            forwarderKilometers: new FormControl(inputData.forwarderKilometers
                ? String(inputData.forwarderKilometers).replace(".", ",")
                : ""),
            forwarderRouteDescription: new FormControl(inputData.forwarderRouteDescription || ""),
            forwarderBdo: new FormControl(inputData.forwarderBdo || ""),
            forwarderCarName: new FormControl(inputData.forwarderCarName || ""),
            forwarderCarType: new FormControl(inputData.forwarderCarType || ""),
            forwarderExpense: new FormControl(inputData.forwarderExpense
                ? String(inputData.forwarderExpense).replace(".", ",")
                : ""),
            forwarderInvoiceNumber: new FormControl(inputData.forwarderInvoiceNumber || ""),
        }, {
            validator: StartEndDateValidator.CheckDates,
        });
        inputData.taskData &&
            inputData.taskData.forEach(function (item, index) {
                return _this.addOrderSubform(index, item);
            });
        this.setRangeEndDateMax(this.taskReceivingForm.get("startDate").value);
        if ((this.mode === "add" || this.mode === "clone") &&
            !!this.data.companyBranchId) {
            this.taskReceivingForm.get("companyBranchId").disable();
        }
        this.searchForwarder.setValue(inputData.originalCompanyName || "");
        this.taskReceivingForm.get("registrationNumFront").disable();
        this.taskReceivingForm.get("registrationNumBack").disable();
        if (this.mode === "view") {
            this.taskReceivingForm.disable();
        }
        if (this.mode === "edit") {
            this.getCompanyCars(inputData.forwarderId);
        }
    };
    TaskReceivingFormComponent.prototype.createSearchFilters = function () {
        var _this = this;
        this.searchCustomerCompany = new FormControl("");
        this.searchTransport = new FormControl("");
        this.searchOrders = new FormControl("");
        this.searchForwarder = new FormControl("");
        this.companyCarSelect = new FormControl("");
        this.forwarderIdSelect = new FormControl("");
        this.searchCustomerCompany.valueChanges
            .debounceTime(300)
            .subscribe(function (value) {
            _this.filteredListsService
                .getCompaniesList(__assign({}, _this.filterParams, { search: value }))
                .subscribe(function (response) {
                _this.customerCompanyOptions = response.customerCompanies;
            });
        });
        this.searchTransport.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.filteredListsService
                .getTransportList(__assign({}, _this.filterParamsFullList, { name: value }))
                .subscribe(function (response) {
                _this.transportOptions = response.transports;
            });
        });
        this.searchOrders.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.filteredListsService
                .getOrdersList(__assign({}, _this.filterOrdersParams, {
                search: value,
                companyBranchUuid: _this.taskReceivingForm.value.companyBranchId,
            }))
                .subscribe(function (response) {
                _this.ordersOptions = response.orders;
            });
        });
        this.searchForwarder.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.filteredListsService
                .getCompaniesList(__assign({}, _this.filterForwarderParams, { search: value }))
                .subscribe(function (response) {
                _this.forwarderCompanies = response.customerCompanies;
            });
        });
        this.companyCarSelect.valueChanges.subscribe(function (companyCar) {
            _this.taskReceivingForm.patchValue({
                forwarderCompanyName: companyCar.companyName,
                forwarderDriverName: companyCar.driverName,
                forwarderDriverPhone: companyCar.driverPhone,
                forwarderRegistrationNumberFront: companyCar.registrationNumberFront,
                forwarderRegistrationNumberBack: companyCar.registrationNumberBack,
                forwarderBdo: companyCar.bdo,
                forwarderCarName: companyCar.name,
                forwarderCarType: companyCar.type,
            });
        });
    };
    TaskReceivingFormComponent.prototype.orderFilter = function () {
        var _this = this;
        this.filteredListsService
            .getOrdersList(__assign({}, this.filterOrdersParams, { companyBranchUuid: this.taskReceivingForm.value.companyBranchId }))
            .subscribe(function (response) {
            _this.ordersOptions = response.orders;
        });
    };
    TaskReceivingFormComponent.prototype.createNewOrders = function (taskData, formCompanyBranchId) {
        var _this = this;
        var taskDataWithOtherType = taskData.filter(function (task) { return task.uuid === "other"; });
        if (!taskDataWithOtherType.length) {
            return of([]);
        }
        var observables = taskDataWithOtherType.map(function (item) {
            if (item.customerCompanyBranch.uuid === "other") {
                delete item.customerCompanyBranch.uuid;
            }
            var tmpCompanyBranchId;
            if (_this.data.companyBranchId) {
                tmpCompanyBranchId = _this.data.companyBranchId;
            }
            else if (_this.task &&
                _this.task.companyBranch &&
                _this.task.companyBranch.uuid) {
                tmpCompanyBranchId = _this.task.companyBranch.uuid;
            }
            else if (_this.task && _this.task.companyBranchId) {
                tmpCompanyBranchId = _this.task.companyBranchId;
            }
            else {
                tmpCompanyBranchId = formCompanyBranchId;
            }
            var newOrder = {
                companyBranchId: tmpCompanyBranchId,
                customerCompanyId: item.customerCompany.uuid,
                salesAccepted: item.salesAccepted,
                availableHoursFrom: item.availableHoursFrom || null,
                availableHoursTo: item.availableHoursTo || null,
                customerCompanyBranch: item.customerCompanyBranch,
                bdo: item.bdo ? item.bdo.toString() : null,
                bdoFree: Boolean(item.bdoFree && !item.bdo),
                bdoPeopleCollection: Boolean(item.bdoPeopleCollection && !item.bdo),
                kpoEmails: item.kpoEmails,
                reinvoice: item.reinvoice,
                price: Number(String(item.price).replace(",", ".")),
                reinvoiceNumber: item.reinvoiceNumber,
                invoiceComment: item.invoiceComment,
                suggestedDate: moment(item.suggestedDate).format("YYYY-MM-DD") ||
                    moment(_this.taskReceivingForm.get("startDate").value).format("YYYY-MM-DD") ||
                    moment().format("YYYY-MM-DD"),
                referenceNumber: item.referenceNumber,
                orderData: mapOrderDataApiValues(item.orderData),
                orderType: OrderTypes.Receiving,
            };
            return _this.ordersService.createOrder(newOrder).pipe(map(function (resOrder) {
                return {
                    uuid: resOrder.uuid,
                    salesAccepted: resOrder.salesAccepted,
                    suggestedDate: resOrder.suggestedDate || null,
                    availableHoursFrom: resOrder.availableHoursFrom || null,
                    availableHoursTo: resOrder.availableHoursTo || null,
                    customerCompanyId: resOrder.customerCompany
                        ? resOrder.customerCompany.uuid
                        : null,
                    customerCompany: resOrder.customerCompany,
                    customerCompanyBranch: resOrder.customerCompanyBranch,
                    bdo: resOrder.bdo ? resOrder.bdo.toString() : null,
                    bdoFree: resOrder.bdoFree && !resOrder.bdo,
                    bdoPeopleCollection: resOrder.bdoPeopleCollection && !resOrder.bdo,
                    kpoEmails: resOrder.kpoEmails,
                    reinvoice: resOrder.reinvoice,
                    price: resOrder.price,
                    reinvoiceNumber: resOrder.reinvoiceNumber,
                    invoiceComment: resOrder.invoiceComment,
                    referenceNumber: resOrder.referenceNumber || null,
                    orderData: item.orderData,
                };
            }));
        });
        return zip.apply(void 0, observables);
    };
    TaskReceivingFormComponent.prototype.mapTaskData = function (taskData) {
        taskData = taskData.map(function (item) {
            var taskDataItem = {
                order: item.order,
                customerCompanyBranch: item.customerCompanyBranch,
                salesAccepted: item.salesAccepted,
                suggestedDate: item.suggestedDate,
                availableHoursFrom: item.availableHoursFrom || null,
                availableHoursTo: item.availableHoursTo || null,
                customerCompanyId: item.customerCompany
                    ? item.customerCompany.uuid
                    : null,
                referenceNumber: item.referenceNumber || null,
                bdo: item.bdo ? item.bdo.toString() : null,
                bdoFree: item.bdoFree && !item.bdo,
                bdoPeopleCollection: item.bdoPeopleCollection && !item.bdo,
                kpoEmails: item.kpoEmails,
                reinvoice: item.reinvoice,
                price: Number(String(item.price).replace(",", ".")) || null,
                reinvoiceNumber: item.reinvoiceNumber,
                invoiceComment: item.invoiceComment,
                orderData: mapOrderDataApiValues(item.orderData),
            };
            if (item.uuid !== "other") {
                taskDataItem["orderId"] = item.uuid;
            }
            return taskDataItem;
        });
        return taskData;
    };
    TaskReceivingFormComponent.prototype.changeStep = function (event) {
        this.indexStep = event.selectedIndex;
    };
    TaskReceivingFormComponent.prototype.prevStep = function () {
        this.indexStep = this.indexStep - 1 >= 0 ? this.indexStep - 1 : 0;
    };
    TaskReceivingFormComponent.prototype.handleCCBranchChange = function (branchId, i) {
        var control = this.taskReceivingForm.controls.taskData;
        var group = control.controls[i];
        var companyId = group.get("customerCompany").value &&
            group.get("customerCompany").value.uuid;
        var branch = companyId &&
            this.customerCompanyBranches[companyId] &&
            this.customerCompanyBranches[companyId].find(function (item) { return item.uuid === branchId; });
        group.get("customerCompanyBranchOther").patchValue({
            name: (branch && branch.name) || null,
            street: (branch && branch.street) || null,
            postalCode: (branch && branch.postalCode) || null,
            city: (branch && branch.city) || null,
            country: (branch && branch.country) || "Polska",
            contact: (branch && branch.contact) || null,
            email: (branch && branch.email) || null,
            phone: (branch && branch.phone) || null,
            note: (branch && branch.note) || null,
            mainBranch: false,
        });
    };
    TaskReceivingFormComponent.prototype.getCustomerCompanyBranches = function (customerCompanyId) {
        return __awaiter(this, void 0, void 0, function () {
            var res, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.companyBranchesService
                                .getCompanyBranchesList({ limit: 500, page: 0 }, customerCompanyId)
                                .toPromise()];
                    case 1:
                        res = _a.sent();
                        this.customerCompanyBranches[customerCompanyId] = res.branches;
                        return [2 /*return*/, true];
                    case 2:
                        e_1 = _a.sent();
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    TaskReceivingFormComponent.prototype.getFormCompanyUuid = function (i) {
        var control = this.taskReceivingForm.controls.taskData;
        var group = control.controls[i];
        return ((group.get("customerCompany").value &&
            group.get("customerCompany").value.uuid) ||
            "-");
    };
    TaskReceivingFormComponent.prototype.getTooltip = function (branch) {
        return branch.name + "\n    " + branch.street + "\n    " + branch.postalCode + " " + branch.city;
    };
    TaskReceivingFormComponent.prototype.getTooltipCompany = function (company) {
        if (company) {
            return company.name + " \n      " + company.address.street + "\n      " + company.address.postalCode + " " + company.address.city;
        }
    };
    TaskReceivingFormComponent.prototype.getCompanyCars = function (forwarderId) {
        var _this = this;
        this.companyCarsService
            .getCompanyCarsList(this.filterParamsFullList, forwarderId)
            .subscribe(function (response) {
            _this.companyCars = response.cars;
        });
    };
    TaskReceivingFormComponent.prototype.selected = function (event) {
        var _this = this;
        this.searchForwarder.patchValue(event.option.viewValue);
        this.companyCarsService
            .getCompanyCarsList(this.filterParamsFullList, event.option.value)
            .subscribe(function (response) {
            _this.companyCars = response.cars;
            _this.taskReceivingForm.patchValue({
                forwarderId: event.option.value,
                forwarderCompanyName: event.option.viewValue,
                forwarderDriverName: null,
                forwarderDriverPhone: null,
                forwarderRegistrationNumberFront: null,
                forwarderRegistrationNumberBack: null,
                forwarderBdo: null,
                forwarderCarName: null,
                forwarderCarType: null,
            });
        });
    };
    TaskReceivingFormComponent.prototype.getPdfNeededData = function () {
        var _this = this;
        this.usersService.getCompanyBranchList().subscribe(function (res) {
            _this.pdfBranch = res.branches.find(function (item) { return item.uuid === _this.task.companyBranch.uuid; });
        });
        if (this.task.forwarderId) {
            this.companiesService
                .getCompany(this.task.forwarderId)
                .subscribe(function (res) {
                _this.pdfForwarder = res;
            });
        }
        else {
            this.pdfForwarder = { name: "Brak Danych", address: "Brak danych" };
        }
    };
    TaskReceivingFormComponent.prototype.getEmailControl = function (index) {
        var control = this.taskReceivingForm.controls.taskData;
        return control.controls[index].get("kpoEmails");
    };
    TaskReceivingFormComponent.prototype.resendNotification = function (index) {
        var _this = this;
        var orderId = this.task.taskData[index].order.uuid;
        this.isLoading = true;
        this.ordersService.resendNotification(orderId).subscribe(function () {
            _this.isLoading = false;
            _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.NOTIFICATION_SENT"), "", { duration: 5000 });
        }, function () {
            _this.isLoading = false;
            _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.ERROR"), "", { duration: 5000 });
        });
    };
    return TaskReceivingFormComponent;
}());
export { TaskReceivingFormComponent };
