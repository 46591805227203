import { COMMA, ENTER, SPACE } from "@angular/cdk/keycodes";
import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material";
import { ClipboardService } from "ngx-clipboard";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-email-chips-input",
  templateUrl: "./email-chips-input.component.html",
  styleUrls: ["./email-chips-input.component.scss"],
})
export class EmailChipsInputComponent
  implements OnInit, OnChanges, OnDestroy, DoCheck
{
  @Input() mode: string;
  @Input() emailControl: FormControl;
  @Input() label?: string;
  subscription: Subscription;
  formControl = new FormControl(Validators.email);
  visible = true;
  selectable = false;
  removable = true;
  addOnBlur = true;
  error = false;
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  selectedEmails: string[] = [];

  @ViewChild("emailInput") emailInput: ElementRef<HTMLInputElement>;
  constructor(
    private clipboard: ClipboardService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.emailControl.value) {
      this.emailControl.setValue([]);
    } else if (
      typeof this.emailControl.value === "string" &&
      this.emailControl.value.length
    ) {
      this.selectedEmails = this.emailControl.value.split(",");
    }
    if (this.mode === "view") {
      this.formControl.disable();
      this.selectable = false;
      this.removable = false;
    } else {
      this.formControl.enable();
    }

    this.subscription = this.emailControl.valueChanges
      .pipe(debounceTime(200))
      .subscribe((value) => {
        if (typeof value === "string" && value.length) {
          this.selectedEmails = value.split(",");
        } else {
          this.selectedEmails = [];
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.emailControl && changes.emailControl.currentValue) {
      const value = changes.emailControl.currentValue.value;
      if (typeof value === "string" && value.length) {
        this.selectedEmails = value.split(",");
      } else {
        this.selectedEmails = [];
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngDoCheck() {
    if (
      typeof this.emailControl.value === "string" &&
      this.selectedEmails.join(",") !== this.emailControl.value
    ) {
      this.selectedEmails = this.emailControl.value.split(",");
      this.cdRef.detectChanges();
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our email
    if ((value || "").trim()) {
      if (
        !value
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        this.error = true;
        return;
      }
      this.error = false;
      this.selectedEmails.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.formControl.setValue(null);
    this.emailControl.setValue(this.selectedEmails.join(","));
  }

  remove(email: string): void {
    this.selectedEmails = this.emailControl.value.split(",");
    const index = this.selectedEmails.indexOf(email);
    if (index >= 0) {
      this.selectedEmails.splice(index, 1);
    }
    this.emailControl.setValue(this.selectedEmails.join(","));
  }

  copy() {
    this.clipboard.copyFromContent(this.selectedEmails.join(", "));
  }
}
