export const FILTERS_DICT = {
  roleType: [
    {
      name: "ALL_USERS",
      value: "",
    },
    {
      name: "ADMIN",
      value: "admin",
    },
    {
      name: "USER",
      value: "user",
    },
  ],
  userType: [
    {
      name: "ALL_USERS",
      value: "",
    },
    {
      name: "LOGISTICIAN",
      value: "logistician",
    },
    {
      name: "MANAGER",
      value: "manager",
    },
    {
      name: "SALES",
      value: "sales",
    },
  ],
  taskType: [
    {
      name: "ALL",
      value: "",
    },
    {
      name: "IMPORT",
      value: "import",
    },
    {
      name: "EXPORT",
      value: "export",
    },
    {
      name: "RECEIVING",
      value: "receiving",
    },
  ],
  companyBranch: [
    {
      name: "ALL",
      value: "",
    },
  ],
  trusted: [
    {
      name: "ALL",
      value: "",
    },
    {
      name: "TRUSTED",
      value: "1",
    },
    {
      name: "NOT_TRUSTED",
      value: "0",
    },
  ],
  isPublic: [
    {
      name: "ALL",
      value: "",
    },
    {
      name: "VISIBLE",
      value: "1",
    },
    {
      name: "INVISIBLE",
      value: "0",
    },
  ],
  salesAccepted: [
    {
      name: "ALL",
      value: "",
    },
    {
      name: "YES",
      value: "1",
    },
    {
      name: "NO",
      value: "0",
    },
  ],
  reinvoice: [
    {
      name: "ALL",
      value: "",
    },
    {
      name: "YES",
      value: "1",
    },
    {
      name: "NO",
      value: "0",
    },
  ],
  orderType: [
    {
      name: "ALL",
      value: "all_options",
    },
    {
      name: "IMPORT",
      value: "import",
    },
    {
      name: "EXPORT",
      value: "export",
    },
    {
      name: "RECEIVING",
      value: "receiving",
    },
  ],
  status: [
    {
      name: "NEW",
      value: "new",
    },
    {
      name: "READ",
      value: "read",
    },
    {
      name: "PLANNED",
      value: "planned",
    },
    {
      name: "CANCELLED",
      value: "cancelled",
    },
    {
      name: "CANCELLED_BY_CLIENT_REQUEST",
      value: "cancelledByClientRequest",
    },
    {
      name: "COMPLETED",
      value: "completed",
    },
    {
      name: "CLOSED",
      value: "closed",
    },
  ],
  taskStatus: [
    {
      name: "NEW",
      value: "new",
    },
    {
      name: "SEND_TO_FORWARDER",
      value: "send_to_forwarder",
    },
    {
      name: "NOTIFIED",
      value: "notified",
    },
    {
      name: "REALIZED",
      value: "realized",
    },
    {
      name: "INVOICED",
      value: "invoiced",
    },
    {
      name: "CANCELLED",
      value: "cancelled",
    },
  ],
  active: [
    {
      name: "SHOW_ALL",
      value: "",
    },
    {
      name: "ACTIVE",
      value: "1",
    },
    {
      name: "INACTIVE",
      value: "0",
    },
  ],
};
