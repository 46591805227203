import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatSnackBar,
} from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { FuseTranslationLoaderService } from "../../../../../@fuse/services/translation-loader.service";
import { RecyclingService } from "../../../../services/recycling.service";
import { RecyclingItem } from "../../../../shared/models/recycling.model";
import { markFormGroupTouched } from "../../../../shared/utils/markFormGroupAsTouched";
import { ValidateGreaterThanZero } from "../../../../shared/validators/greaterThanZero.validator";
import { locale as english } from "../../../../translations/en";
import { locale as polish } from "../../../../translations/pl";
import { ConfirmModalComponent } from "../../../shared/components/confirm-modal/confirm-modal.component";

@Component({
  selector: "app-recycling-items-editor",
  templateUrl: "./recycling-items-editor.component.html",
  styleUrls: ["./recycling-items-editor.component.scss"],
})
export class RecyclingItemsEditorComponent implements OnInit {
  public recyclingItemForm: FormGroup;
  public recyclingItemUuid: RecyclingItem["uuid"];
  public recyclingItem;
  public mode: string;
  public isLoading = false;

  constructor(
    public recyclingItemEditorDialogRef: MatDialogRef<RecyclingItemsEditorComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matDialog: MatDialog,
    private recyclingService: RecyclingService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this.mode = data.mode;
    this.recyclingItemUuid = data.recyclingItemUuid;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    this.createForm({});
    if (this.mode === "edit" || this.mode === "view") {
      this.recyclingService
        .getRecyclingItem(this.recyclingItemUuid)
        .subscribe((res) => {
          this.recyclingItem = res;
          this.createForm(this.recyclingItem);
        });
    }
  }

  public submitRecyclingItem() {
    this.isLoading = true;
    markFormGroupTouched(this.recyclingItemForm);
    if (this.recyclingItemForm.invalid) {
      this.isLoading = false;
      return;
    }
    if (this.mode === "add") {
      this.recyclingService
        .createRecyclingItem(this.recyclingItemForm.value)
        .subscribe(
          () => {
            this.recyclingItemEditorDialogRef.close();

            this.recyclingItemEditorDialogRef.afterClosed().subscribe(() => {
              this.recyclingService.onEditEvent.emit();
            });

            this.snackBar.open(
              this.translateService.instant(
                "RECYCLING.MESSAGES.CREATE_SUCCESS"
              ),
              "",
              { duration: 5000 }
            );
          },
          () => {
            this.isLoading = false;
            this.snackBar.open(
              this.translateService.instant("RECYCLING.MESSAGES.CREATE_ERROR"),
              "",
              { duration: 5000 }
            );
          }
        );
    } else {
      this.recyclingService
        .updateRecyclingItem(
          this.recyclingItemUuid,
          this.recyclingItemForm.value
        )
        .subscribe(
          () => {
            this.recyclingItemEditorDialogRef.close();

            this.recyclingItemEditorDialogRef.afterClosed().subscribe(() => {
              this.recyclingService.onEditEvent.emit();
            });

            this.snackBar.open(
              this.translateService.instant(
                "RECYCLING.MESSAGES.UPDATE_SUCCESS"
              ),
              "",
              { duration: 5000 }
            );
          },
          () => {
            this.isLoading = false;
            this.snackBar.open(
              this.translateService.instant("RECYCLING.MESSAGES.UPDATE_ERROR"),
              "",
              { duration: 5000 }
            );
          }
        );
    }
  }

  public deleteRecyclingItem() {
    let deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false,
    });

    deleteDialogRef.componentInstance.confirmMessage =
      this.translateService.instant("RECYCLING.MESSAGES.DELETE_QUESTION");
    deleteDialogRef.componentInstance.titleMessage =
      this.translateService.instant(
        "RECYCLING.MESSAGES.DELETE_RECYCLING_TITLE"
      );
    deleteDialogRef.componentInstance.confirmButton =
      this.translateService.instant("RECYCLING.DELETE");

    deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.recyclingService
          .deleteRecyclingItem(this.recyclingItemUuid)
          .subscribe(
            () => {
              this.recyclingItemEditorDialogRef.close();
              this.recyclingItemEditorDialogRef.afterClosed().subscribe(() => {
                this.recyclingService.onEditEvent.emit();
              });
              this.snackBar.open(
                this.translateService.instant(
                  "RECYCLING.MESSAGES.DELETE_SUCCESS"
                ),
                "",
                { duration: 5000 }
              );
            },
            () => {
              this.snackBar.open(
                this.translateService.instant(
                  "RECYCLING.MESSAGES.DELETE_ERROR"
                ),
                "",
                { duration: 5000 }
              );
            }
          );
      }
    });
  }

  public enableEditMode() {
    this.recyclingItemEditorDialogRef.close();
    this.matDialog.open(RecyclingItemsEditorComponent, {
      panelClass: "recycling-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "edit",
        recyclingItemUuid: this.recyclingItemUuid,
      },
    });
  }

  public getTitle(): string {
    switch (this.mode) {
      case "add":
        return this.translateService.instant("RECYCLING.TITLE_ADD");
      case "edit":
        return this.translateService.instant("RECYCLING.TITLE_EDIT");
      case "view":
        return this.translateService.instant("RECYCLING.TITLE_VIEW");
      default:
        return;
    }
  }

  private createForm(inputData: RecyclingItem | any) {
    this.recyclingItemForm = this.formBuilder.group({
      name: new FormControl(inputData.name || "", Validators.required),
      group: new FormControl(inputData.group || ""),
      code: new FormControl(inputData.code || ""),
      examples: new FormControl(inputData.examples || ""),
      description: new FormControl(inputData.description || ""),
      isPublic: new FormControl(
        this.mode === "add" ? true : inputData.isPublic
      ),
      active: new FormControl(this.mode === "add" ? true : inputData.active),
      weightPerPiece: new FormControl(inputData.weightPerPiece || null, [
        Validators.required,
        Validators.pattern("[\\d]+[.]{0,1}[\\d]{0,3}"),
        ValidateGreaterThanZero,
      ]),
      weightPerCubicMeter: new FormControl(
        inputData.weightPerCubicMeter || null,
        [
          Validators.required,
          Validators.pattern("[\\d]+[.]{0,1}[\\d]{0,3}"),
          ValidateGreaterThanZero,
        ]
      ),
    });

    if (this.mode === "view") {
      this.recyclingItemForm.disable();
    }
  }
}
